// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-account-js": () => import("/opt/build/repo/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-analytics-js": () => import("/opt/build/repo/src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-connect-js": () => import("/opt/build/repo/src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("/opt/build/repo/src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-labels-js": () => import("/opt/build/repo/src/pages/labels.js" /* webpackChunkName: "component---src-pages-labels-js" */),
  "component---src-pages-performance-js": () => import("/opt/build/repo/src/pages/performance.js" /* webpackChunkName: "component---src-pages-performance-js" */),
  "component---src-pages-quote-js": () => import("/opt/build/repo/src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-settings-js": () => import("/opt/build/repo/src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-shipments-js": () => import("/opt/build/repo/src/pages/shipments.js" /* webpackChunkName: "component---src-pages-shipments-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

